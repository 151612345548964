import React from 'react'
import { Link } from 'gatsby'

const MyExperience = () => {
    return (
        <div className="experience-area border-bottom ptb-100">
            <div className="common-right-text">
                <span>EXPERIENCE</span>
            </div>

            <div className="container">
                <div className="section-title">
                    <span className="sub-title">MY EXPERIENCE</span>
                    <h2>I Have <span>10+</span> Years Of Experience In FinTech And API's. </h2>
                    <p>I swiped right with fintech and have never been happier!</p>
                </div>
                <div className="experience-content">
                    <div className="experience-inner">
                        <ul className="align-items-center">
                            <li>
                                <span>2021 - 2023 </span>
                            </li>
                            <li>
                                <span>Senior Support Engineer, Synapse Financial, San Francisco </span>
                            </li>
                            <li>
                                <p>Finicity enables a secure financial data-sharing ecosystem through our real-time data aggregation platform. In my role I help clients dependent on transaction aggregation.</p>
                            </li>
                        </ul>
                    </div>
                    </div>
                <div className="experience-content">
                    <div className="experience-inner">
                        <ul className="align-items-center">
                            <li>
                                <span>2020- 2021</span>
                            </li>
                            <li>
                                <span>Senior Support Engineer, Mastercard, Salt Lake City</span>
                            </li>
                            <li>
                                <p>Finicity enables a secure financial data-sharing ecosystem through our real-time data aggregation platform. In my role I help clients dependent on transaction aggregation.</p>
                            </li>
                        </ul>
                    </div>

                    <div className="experience-inner">
                        <ul className="align-items-center">
                            <li>
                                <span>2017-2019</span>
                            </li>
                            <li>
                                <span>Implementation Engineer, Nuxiba</span>
                            </li>
                            <li>
                                <p>Nuxiba, a SaaS for contact centers globally. My role was update the incumbent telephony software with a tailored Nuxiba bundle.</p>
                            </li>
                        </ul>
                    </div>

                    <div className="experience-inner">
                        <ul className="align-items-center">
                            <li>
                                <span>2016-2017</span>
                            </li>
                            <li>
                                <span>React Developer, DevPoint Labs.</span>
                            </li>
                            <li>
                                <p>My team created a webapp for a local night club. Site Visitors were able to book tables alone or join groups of other site visitors who needed to fill a space at their booked table. We also added profiles to the site visitor that included interests and photo.</p>
                            </li>
                        </ul>
                    </div>

                    <div className="experience-inner">
                        <ul className="align-items-center">
                            <li>
                                <span>2012-2016</span>
                            </li>
                            <li>
                                <span>B.I. Analyst, TCN Inc. </span>
                            </li>
                            <li>
                                <p>The debt collection industry gained attention with the invention of VoIP as a result heavy regulation was legislated. I consulted with our clients to create metrics to identify new strategies to out perform their competators.</p>
                            </li>
                        </ul>
                    </div>

                    <p>I have also done many freelance work alongside with the company's work which helps me to upgrade my skills and passion.</p>

                    <div className="text-center">
                        <Link to="#" className="common-btn">
                            See My Portfolio
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyExperience