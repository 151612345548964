import React from 'react'
import { Link } from 'gatsby'
import portfolio1img from '../../components/App/assets/images/portfolio/portfolio1.jpg'
import portfolio2img from '../../components/App/assets/images/portfolio/portfolio2.jpg'
import portfolio3img from '../../components/App/assets/images/portfolio/portfolio3.jpg'
import portfolio4img from '../../components/App/assets/images/portfolio/portfolio4.jpg'
import portfolio5img from '../../components/App/assets/images/portfolio/portfolio5.jpg'

const Portfolio = () => {
    return (
        <div id="portfolio" className="portfolio-area border-bottom ptb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">PORTFOLIO</span>
                    <h2>I can show you a <span>whole new </span>world!</h2>
                </div>
                
                <div className="row">
                    <div className="col-sm-6 col-lg-6">
                        <div className="portfolio-item">
                            <div className="top">
                                <img src={portfolio1img} alt="Portfolio" />
                            </div>
                            <div className="bottom">
                                <div className="bottom-one">
                                    <h3>
                                        <Link to="https://vibrant-albattani-e64810.netlify.app/">
                                            Card Game App
                                        </Link>
                                    </h3>
                                    <span>Practice with 21</span>
                                </div>
                                <div className="bottom-two">
                                    <p>I used react to create an ecosystem for future builds to practice card counting. </p>

                                    <Link to="https://vibrant-albattani-e64810.netlify.app/">
                                        Explore More <i className="flaticon-right-arrow"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-6">
                        <div className="portfolio-item">
                            <div className="top">
                                <img src={portfolio2img} alt="Portfolio" />
                            </div>
                            <div className="bottom">
                                <div className="bottom-one">
                                    <h3>
                                        <Link to="https://thirsty-brahmagupta-381bfa.netlify.app/">
                                            My College Website
                                        </Link>
                                    </h3>
                                    <span>HomePage</span>
                                </div>
                                <div className="bottom-two">
                                    <p>This was my first website coming out of College. React and Ruby.o.Rails for the backend. </p>
                                    <Link to="https://thirsty-brahmagupta-381bfa.netlify.app/">
                                        Explore More <i className="flaticon-right-arrow"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="portfolio-item">
                            <div className="top">
                                <img src={portfolio3img} alt="Portfolio" />
                            </div>
                            <div className="bottom">
                                <div className="bottom-one">
                                    <h3>
                                        <Link to="https://focused-lumiere-4e9b43.netlify.app/" >
                                            The Love API
                                        </Link>
                                    </h3>
                                    <span>Card</span>
                                </div>
                                <div className="bottom-two">
                                    <p>Created this project to explore Google Cloud Console's Firebase</p>
                                    <Link to="https://focused-lumiere-4e9b43.netlify.app/">
                                        Explore More <i className="flaticon-right-arrow"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-6">
                        <div className="portfolio-item">
                            <div className="top">
                                <img src={portfolio4img} alt="Portfolio" />
                            </div>
                            <div className="bottom">
                                <div className="bottom-one">
                                    <h3>
                                        <Link to="https://thirsty-easley-664c05.netlify.app/">
                                            Birthday e-invite
                                        </Link>
                                    </h3>
                                    <span> Landing Page for Lindsay Jungst </span>
                                </div>
                                <div className="bottom-two">
                                    <p>This was a fun way to put together a party. Sending the invite out via text and QR Code</p>
                                    <Link to="https://thirsty-easley-664c05.netlify.app/">
                                        Explore More <i className="flaticon-right-arrow"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>

                <div className="text-center">
                    <Link to="#" className="common-btn">
                        Explore All Work
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Portfolio