import React from 'react'
import { Link } from 'gatsby'

const About = () => {
    return (
        <div id="about" className="about-area border-bottom ptb-100">
            <div className="common-right-text">
                <span>ABOUT</span>
            </div>

            <div className="container">
                <div className="about-content">
                    <div className="section-title">
                        <span className="sub-title">ABOUT ME</span>
                        <h2>Silicon Slopes Technology Enthusist.</h2>
                        <p>I enjoy API's and working with technology to create something useful. Automation to give me some time to breath. When I'm not behind a desk you'll find me on a snowy mountain or on the lake!</p>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-lg-6">
                            <div className="left">
                                <ul>
                                    <li>
                                        <span>Age:</span> 34 Years
                                    </li>
                                    <li>
                                        <span>Residence:</span> Salt Lake City Metro
                                    </li>
                                
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-6 col-lg-6">
                            <div className="right">
                                <ul>
                                    <li>
                                        <span>Phone:</span>
                                        <a href="tel:+18019894888">+1(801)989-4888</a>
                                    </li>
                                    <li>
                                        <span>Email:</span>
                                        <a href="mailto:JerginsCharlie@gmail.com">JerginsCharlie@gmail.com</a>
                                    </li>
                                    <li>
                                        <span>Website:</span>
                                        <Link to="/" >
                                            You are here already.
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About