import React, { useState } from 'react'
import axios from 'axios'
import baseUrl from '../../utils/baseUrl'
import { useForm } from 'react-hook-form'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const MySwal = withReactContent(Swal)

const alertContent = () => {
    MySwal.fire({
        title: "We're Friends Now",
        text: 'Your message was successfully send and will back to you soon',
        icon: 'success',
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
    })
}

// Form initial state
const INITIAL_STATE = {
    name: "",
    email: "",
    number: "",
    subject: "",
    text: ""
};

const ContactForm = () => {

    const [contact, setContact] = useState(INITIAL_STATE);
    const { register, handleSubmit, errors } = useForm();

    const handleChange = e => {
        const { name, value } = e.target;
        setContact(prevState => ({ ...prevState, [name]: value }));
        // console.log(contact)
    }

    const onSubmit = async e => {
       // e.preventDefault();
        try {
            const url = "https://api.emailjs.com/api/v1.0/email/send"
            const { name, email, number, subject, text } = contact;
            const payload = { name, email, number, subject, text };
            const headers = {user_id: "user_5rV9mbyE2QLYgUUUhwhd2",
                             service_id: "service_crkbcn9",
                             template_id: "template_7niemfs",
                             template_params: payload
                            };

            await axios.post(url, headers);
            
            setContact(INITIAL_STATE);
            alertContent();
        } catch (error) {
            console.log(error)
        }
    };
    



    return (
        <div id="contact" className="contact-area border-bottom pt-100 pb-70">
            <div className="common-right-text">
                <span>CONTACT</span>
            </div>

            <div className="container">
                <div className="section-title">
                    <span className="sub-title">CONTACT</span>
                    <h2>Sitting On Your <span>Idea?</span> Please Drop A Message</h2>
                    <p>I'll meet virtually or if you're open to coffee I know of a bad ass coffee shop downtown.</p>
                </div>

                <div className="row align-items-center">
                    <div className="col-md-6 col-lg-6">
                        <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    name="name" 
                                    className="form-control" 
                                    placeholder="Name" 
                                    value={contact.name}
                                    onChange={handleChange}
                                    ref={register({ required: true })}
                                />
                                <div className='invalid-feedback' style={{display: 'block'}}>
                                    {errors.name && 'Name is required.'}
                                </div>
                            </div>
                                 
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    name="email" 
                                    className="form-control" 
                                    placeholder="Email" 
                                    value={contact.email}
                                    onChange={handleChange}
                                    ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                                />
                                <div className='invalid-feedback' style={{display: 'block'}}>
                                    {errors.email && 'Email is required.'}
                                </div>
                            </div>
                
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    name="subject" 
                                    className="form-control" 
                                    placeholder="Subject" 
                                    value={contact.subject}
                                    onChange={handleChange}
                                    ref={register({ required: true })}
                                />
                                <div className='invalid-feedback' style={{display: 'block'}}>
                                    {errors.subject && 'Subject is required.'}
                                </div>
                            </div>
                              
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    name="number" 
                                    className="form-control" 
                                    placeholder="Phone" 
                                    value={contact.number}
                                    onChange={handleChange}
                                    ref={register({ required: true })}
                                />
                                <div className='invalid-feedback' style={{display: 'block'}}>
                                    {errors.number && 'Number is required.'}
                                </div>
                            </div>
                        
                            <div className="form-group">
                                <textarea 
                                    name="text" 
                                    className="form-control" 
                                    cols="30" 
                                    rows="6" 
                                    placeholder="Your message" 
                                    value={contact.text}
                                    onChange={handleChange}
                                    ref={register({ required: true })}
                                />
                                <div className='invalid-feedback' style={{display: 'block'}}>
                                    {errors.text && 'Text body is required.'}
                                </div>
                            </div>
                                
                            <button type="submit" className="btn common-btn">Send Message <span></span></button>
                        </form>
                    </div>

                    <div className="col-md-6 col-lg-6">
                        <div className="contact-content">
                            <div className="top">
                            <ul>
                                    <li>
                                        <span>Phone:</span>
                                        <a href="tel:+18019894888">+1(801)989-4888</a>
                                    </li>
                                    <li>
                                        <span>Email:</span>
                                        <a href="mailto:JerginsCharlie@gmail.com">JerginsCharlie@gmail.com</a>
                                    </li>
                                    <li>
                                        <span>Address:</span>
                                        <a href="https://www.google.com/search?q=salt+lake+city+utah" target="_blank" rel="noreferrer" >Salt Lake City Utah, USA</a>
                                    </li>
                                    
                                </ul>
                            </div>

                            <div className="bottom">
                                <ul>
                                    <li>
                                        <a href="https://github.com/charlesbadjr" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-github'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/chuckJergins" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-twitter'></i>
                                        </a>
                                    </li>
                                    
                                    <li>
                                        <a href="https://www.linkedin.com/in/charlie-jergins/" target="_blank" rel="noreferrer" >
                                            <i className='bx bxl-linkedin'></i>
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm