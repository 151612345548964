import React from 'react'

import review1 from '../App/assets/images/review/review1.jpg'
import review2 from '../App/assets/images/review/review2.jpg'
import review3 from '../App/assets/images/review/review3.jpg'

import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
    items: 1,
    loop: true,
    margin: 0,
    nav: true,
    dots: false,
    smartSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    navText: [
        "<i class='bx bx-chevron-left'></i>",
        "<i class='bx bx-chevron-right'></i>"
    ],
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="review-area border-bottom ptb-100">
            <div className="common-right-text">
                <span>REVIEW</span>
            </div>

            <div className="container">
                <div className="section-title">
                    <span className="sub-title">TESTIMONIALS</span>
                    <h2>What My <span>Clients</span> Say</h2>
                </div>

                {display ? <OwlCarousel 
                    className="review-slider owl-carousel owl-theme"
                    {...options}
                >
                    <div className="review-item">
                        <i className='bx bxs-quote-right'></i>
                        <p>I am really pleased with my resume site. People everywhere get hit up by recruiters on LinkedIn for jobs that they're not qualified. This resume site helped lower those annoying recruiters to just the right positions. </p>
                        <img src={review1} alt="Review" />
                        <h3>Benjamin Esplin</h3>
                        <span>Senior Marketer </span>
                    </div>

                    <div className="review-item">
                        <i className='bx bxs-quote-right'></i>
                        <p>Briggs Bees has done well since it started but I had no idea how well it should have done. Charlie helped me refine my marketing strategy and set up sales funnels that doubled my yearly orders. 10/10 stars would reccomend to anyone.</p>
                        <img src={review2} alt="Review" />
                        <h3>Robby Briggs</h3>
                        <span>CEO Briggs Bees</span>
                    </div>

                    <div className="review-item">
                        <i className='bx bxs-quote-right'></i>
                        <p>My website was done by Charlie. You should check it out. </p>
                        <img src={review3} alt="Review" />
                        <h3>Lindsay Bowers</h3>
                        <span>CEO Lu and Ru </span>
                    </div>

                    <div className="review-item">
                        <i className='bx bxs-quote-right'></i>
                        <p>I own a tattoo shop in Ogden, Utah. Charlie was very patient allowing me to get all my creative juices flowing before we finally settled on an idea. Many revisions later we have a site I am proud of.</p>
                        <img src={review3} alt="Review" />
                        <h3>Tattoo Guy</h3>
                        <span>Tatto Ledgend </span>
                    </div>
                </OwlCarousel> : ''}
            </div>
        </div>
    )
}

export default Testimonials