import React from 'react'
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'


const MainBanner = () => {

    return (
        <div id="home" className="banner-area border-bottom">
            <div className="common-right-text two">
                <span>CJ</span>
            </div>

            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="banner-content">
                            <h2>Charlie Jergins</h2>
                            <p>Passionate about leveraging <span>technology</span> to disrupt the status quo. While I consider myself technical I have a need to help and connect with people, I enjoy the <span>team </span> aspect of the tech industry.</p>

                            <div className="banner-btn-area">
                              <AnchorLink 
                                    // onClick={toggleNavbar} 
                                    offset={() => -1} 
                                    className="common-btn banner-btn"
                                    href="#contact"
                                >
                                    Contact
                                </AnchorLink>
                                
                                <AnchorLink 
                                // onClick={toggleNavbar} 
                                  offset={() => -1} 
                                  className="common-btn banner-btn"
                                  href="#portfolio"
                            >
                                    Magic Carpet Ride
                                </AnchorLink>
                            </div>

                            <ul>
                                 <li>
                                     <Link to="https://github.com/charlesbadjr" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-github'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://twitter.com/chuckJergins" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/in/charlie-jergins/" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainBanner