import React from 'react'
import { Link } from 'gatsby'
import { useRecoilState } from 'recoil'
import {sModalState} from '../../utils/recoil-atoms'
import VegasApp from '../../components/App/assets/images/portfolio/portfolio1.jpg';
import Original from '../../components/App/assets/images/portfolio/portfolio2.jpg';
import LoveHub from '../../components/App/assets/images/portfolio/portfolio3.jpg';
import Lindsay from '../../components/App/assets/images/portfolio/portfolio4.jpg';
import DogTinder from '../../components/App/assets/images/portfolio/portfolio5.jpg';

const SidebarDemosModal = () => {
    const [sidebarModal, setSidebarModal] = useRecoilState(sModalState)
 
    const closeModal = () => {
        setSidebarModal(!sidebarModal)
    }

    return (  
        <React.Fragment>
            <div className={`demos-side-content ${sidebarModal ? 'show' : null}`}>
                <div className="modal-header">
                    <h2>View Websites</h2>
                    <button type="button" className="close" onClick={closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                
                <div className="modal-body">
                    <ul>
                        <li>
                            <div className="demo-item">
                                <Link to="https://thirsty-brahmagupta-381bfa.netlify.app/" target="_blank" onClick={closeModal}>
                                    <span>Original Homepage</span>
                                    <img src={Original} alt="websites" />
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="demo-item">
                                <Link to="https://vibrant-albattani-e64810.netlify.app/" target="_blank" onClick={closeModal}>
                                    <span>Vegas Card Game</span>
                                    <img src={VegasApp} alt="websites" />
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="demo-item">
                                <Link to="https://focused-lumiere-4e9b43.netlify.app/" target="_blank" onClick={closeModal}>
                                    <span>Love Quote API</span>
                                    <img src={LoveHub} alt="websites" />
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="demo-item">
                                <Link to="https://thirsty-easley-664c05.netlify.app/" target="_blank" onClick={closeModal}>
                                    <span>Client HomePage</span>
                                    <img src={Lindsay} alt="websites" />
                                </Link>
                            </div>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SidebarDemosModal